import React, {useContext} from "react"
import PropTypes from "prop-types";
import {Box, Typography} from "@mui/material";
import {MainDataContext} from "../../../App";

const styles = {
    root: {
        color: "#eee",
        textStroke: "#000",
        webkitTextStroke: "#000",
        textShadow: "1px 1px 0 #666, -1px 1px 0 #666, 1px -1px 0 #666, -1px -1px 0 #666",
        userSelect: "none",
    }
}



const TimeLabelView = (props) => {

    const {state} = useContext(MainDataContext)

    return (
        <Box sx={{...props.sx, ...styles.root}}>
            <Typography variant="h3">{state.trafficTime}</Typography>
        </Box>
    )
}

TimeLabelView.propTypes = {
    sx: PropTypes.object,
}

export default TimeLabelView
