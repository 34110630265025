import React, {useEffect, useState} from "react"
import maplibregl from "maplibre-gl"
import TrafficLayer from "./layer/traffic"
import "maplibre-gl/dist/maplibre-gl.css"

const LSKEY_LAST_MAP_VIEW_STATE = "last_map_view_state"

const MainMapView = (props) => {

    const [viewState, setViewState] = useState()
    const [map, setMap] = useState()

    useEffect(() => {
        initialState()
    }, [])

    useEffect(() => {
        if (!viewState) { return }
        const m = new maplibregl.Map({
            container: "map",
            style: "https://basemaps.cartocdn.com/gl/positron-gl-style/style.json",
            center: viewState.lngLat,
            zoom: viewState.zoom,
            transformRequest: (url, resourceType) => {
                if (url.match(/\/v3\/maps/)) {
                    return {
                        url: url,
                        headers: {
                            authorization: "Bearer eyJhbGciOiJIUzI1NiJ9.eyJhIjoiYWNfbW9lNWtsbiIsImp0aSI6ImNmMjJkMjM0In0.06IlyNpy4flr79R1iXLAGyhGpAJ9W7F49o-CbfVgaYs"
                        },
                    }
                }
                return null
            },
        })

        m.on("load", () => {
            setMap(m)
        })
    }, [viewState])

    useEffect(() => {
        if (!map) { return }

        const nav = new maplibregl.NavigationControl()
        map.addControl(nav, 'top-left')

    }, [map])


    const initialState = () => {
        if (localStorage.getItem(LSKEY_LAST_MAP_VIEW_STATE)) {
            setViewState(JSON.parse(localStorage.getItem(LSKEY_LAST_MAP_VIEW_STATE)))
        } else {
            setViewState({
                lngLat: [process.env.REACT_APP_MAP_INITIAL_LONGITUDE, process.env.REACT_APP_MAP_INITIAL_LATITUDE],
                zoom: process.env.REACT_APP_MAP_INITIAL_ZOOM
            })
        }
    }

    return (
        <div id="map" style={{width: "100vw", height: "100vh", overflow: "hidden"}}>
            <TrafficLayer map={map} onClick={(e) => console.log(e.features)} />
        </div>
    )
}

export default MainMapView
