import initialState from "./state"
import reducer, {ActionType} from "./reducer"
import { useReducer } from "react"

const UseMainData = () => {
    const [state, dispatch] = useReducer(reducer, {
        ...initialState
    })

    const setTrafficDate = (date) => {
        dispatch({type: ActionType.SetTrafficDate, value: date})
    }

    const setTrafficTime = (time) => {
        dispatch({type: ActionType.SetTrafficTime, value: time})
    }

    const setTrafficTimeSlot = (slot) => {
        dispatch({type: ActionType.SetTrafficTimeSlot, value: slot})
    }

    return {
        state,
        setTrafficDate,
        setTrafficTime,
        setTrafficTimeSlot,
    }
}

export default UseMainData
