import React, {useContext} from "react"
import {Box, Button, Typography} from "@mui/material";
import PropTypes from "prop-types";
import {MainDataContext} from "../../App";
import {ViewType} from "../../data/state";
import MainMapView from "./map";
import SliderView from "./slider";
import TimeLabelView from "./time_label";

const styles = {
    root: {
        display: "flex",
        flexDirection: "row",
        gap: "1em",
    },
    col: {
        backgroundColor: "#eef",
    }
}

const MainView = (props) => {

    return (
        <Box sx={{...styles.root, ...props.style}}>
            <MainMapView />
            <SliderView sx={{
                position: "absolute",
                bottom : "16px",
                left: "32px",
                width: "calc(100% - 64px)",
            }} />
            <TimeLabelView
                sx={{
                    position: 'absolute',
                    bottom: '80px',
                    right: '42px',
                }}
            />
        </Box>
    )

}

MainView.propTypes = {
    style: PropTypes.object,
}

export default MainView
