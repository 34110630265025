


const MainState = {
    trafficDate: null,
    trafficTime: null,
    trafficTimeSlot: 1,
}

export default MainState
