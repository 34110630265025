import dayjs from "dayjs";

export const ActionType = {
    SetTrafficDate: "SET_TRAFFIC_DATE",
    SetTrafficTime: "SET_TRAFFIC_TIME",
    SetTrafficTimeSlot: "SET_TRAFFIC_SLOT",
}

const MainDataReducer = (state, action) => {

    let time = null
    let slot = null

    if (action.type === ActionType.SetTrafficTime) {
        if (action.value) {
            let tm = action.value.trim().replaceAll(' ', '')
            let match = tm.match(/^([0-9]+)\:([0-9]+)$/)
            if (match) {
                time = tm
                slot = parseInt(match[1]) * 60 + parseInt(match[2])
            }
        }
    }
    if (action.type === ActionType.SetTrafficTimeSlot) {
        if (action.value) {
            slot = parseInt(action.value)
            time = ('0' + Math.floor(slot / 60)).slice(-2) + ':' + ('0' + (slot % 60)).slice(-2)
        }
    }

    switch(action.type) {
        case ActionType.SetTrafficDate:
            return {...state, trafficDate: action.value}
        case ActionType.SetTrafficTime:
            return {...state, trafficTime: time, trafficTimeSlot: slot}
        case ActionType.SetTrafficTimeSlot:
            return {...state, trafficTime: time, trafficTimeSlot: slot}
        default:
            break
    }

    return state
}

export default MainDataReducer
