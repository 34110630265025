import PropTypes from "prop-types";
import {useContext, useEffect, useMemo, useState} from "react";
import {MainDataContext} from "../../../../App";
import axios from "axios";
import dayjs from "dayjs";

const SOURCE_NAME = "traffic"
const LAYER_NAME = "traffic_layer"

let timer = null

const TrafficLayer = (props) => {

    const map = useMemo(() => props.map, [props])
    const { state } = useContext(MainDataContext)
    const [initialized, setInitialized] = useState(false)
    const canvas = useMemo(() => map?.getCanvasContainer(), [map])

    useEffect(() => {
        if (!map || initialized) { return }
        initLayer()
    }, [map])

    useEffect(() => {
        if (!initialized) { return }
        console.log(state.trafficTimeSlot)
        updateLayer()
    }, [initialized, state.trafficDate, state.trafficTimeSlot])

    const initLayer = () => {


            map.addSource(SOURCE_NAME, {
                type: "vector",
                tiles: ['https://gcp-asia-northeast1.api.carto.com/v3/maps/takuman_demo/query/{z}/{x}/{y}?q=SELECT%20*%20FROM%20highway_merge&cache=1682182131246&geomType=lines&geo_column=geom&formatTiles=mvt&v=3.1'],
            })

            map.addLayer({
                id: LAYER_NAME + '_line',
                type: "line",
                source: SOURCE_NAME,
                'source-layer': 'default',
                bounds: [139.7112,35.6524,139.7791,35.7033],
                minzoom: 10,
                maxzoom: 22,
                layout: {
                    "line-join": "round",
                    "line-cap": "round",
                },
                "paint": {
                    "line-width": 3,
                    "line-color": [
                        "step",
                        ["get", `speed${state.trafficTimeSlot}`],
                        "#870000",
                        10, "#bf360c",
                        20, "#e64a19",
                        30, "#ff6f00",
                        40, "#f57f17",
                        50, "#fbc02d",
                        60, "#afb42b",
                        70, "#8bc34a",
                        80, "#9ccc65",
                    ]
                }
            })

            map
                .on("click", `${LAYER_NAME}_line`, (e) => {
                    props.onClick && props.onClick(e)
                })
                .on("mouseenter", `${LAYER_NAME}_line`, () => {
                    canvas.style.cursor = "pointer"
                })
                .on("mouseleave", `${LAYER_NAME}_line`, () => {
                    canvas.style.cursor = "grab"
                })

            props.onLoad && props.onLoad()
            setInitialized(true)

    }

    const updateLayer = () => {
        timer && clearTimeout(timer)
        timer = setTimeout(() => {
            map.setPaintProperty(`${LAYER_NAME}_line`, "line-color", [
                "step",
                ["get", `speed${state.trafficTimeSlot}`],
                "#870000",
                10, "#bf360c",
                20, "#e64a19",
                30, "#ff6f00",
                40, "#f57f17",
                50, "#fbc02d",
                60, "#afb42b",
                70, "#8bc34a",
                80, "#9ccc65",
            ])
        }, 100)
    }

}

TrafficLayer.propTypes = {
    map: PropTypes.object,
    onLoad: PropTypes.func,
    onUpdate: PropTypes.func,
    onClick: PropTypes.func,
}

export default TrafficLayer
