import React, {useContext} from "react"
import {Box, Slider} from "@mui/material";
import PropTypes from "prop-types";
import {MainDataContext} from "../../../App";

const styles = {
    root: {
        backgroundColor: "white",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        borderRadius: "8px",
        height: "60px",
        border: "1px solid #000",
    }
}

const timeSliderMarks = [
    {
        value: 0,
        label: "00:00",
    },
    {
        value: 240,
        label: "04:00",
    },
    {
        value: 480,
        label: "08:00"
    },
    {
        value: 720,
        label: "12:00",
    },
    {
        value: 960,
        label: "16:00",
    },
    {
        value: 1200,
        label: "20:00",
    },
    {
        value: 1440,
        label: "24:00",
    }
]

const SliderView = (props) => {

    const { state, setTrafficTimeSlot } = useContext(MainDataContext)

    const valuetext = (val) => {
        let h = Math.floor(val / 60)
        let m = val - (h * 60)
        return `0${h}`.slice(-2) + ':' + `0${m}`.slice(-2)
    }

    const onChange = (e) => {
        console.log(e, e.target.value)
        setTrafficTimeSlot(e.target.value)
    }

    return (
        <Box sx={{...props.sx, ...styles.root}}>
            <Slider
                aria-label="Temperature"
                value={state.trafficTimeSlot}
                valueLabelFormat={valuetext}
                valueLabelDisplay="auto"
                step={1}
                marks={timeSliderMarks}
                min={0}
                max={1440}
                style={{ width: "calc(100% - 48px)", }}
                onChange={onChange}
            />
        </Box>
    )
}

SliderView.propTypes = {
    sx: PropTypes.object,
}

export default SliderView
