import React from "react"
import './App.css'
import UseMainData from "./data"
import MainView from "./views/main";

export const MainDataContext = React.createContext()

const App = () => {

  const useMainData = UseMainData()

  return (
      <MainDataContext.Provider value={useMainData}>
        <MainView style={{margin: 0, width: "100vw", height: "100vh"}} />
      </MainDataContext.Provider>
  )
}
export default App

